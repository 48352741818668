import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../components/layout"
import { FormationShop, ShopActions, ShopAction } from "../../data/types/formations"

interface Props {
  pathContext: {
    formationShop: FormationShop
    shopActions: ShopActions
    shopAction: ShopAction
  }
}

const iframeStyle = {
  width: "100%",
  height: "750px",
  border: "none",
}

const propulsedStyle = {
  width: "100%",
  textAlign: "center" as const,
}

const ReservationDetection: React.FC<Props> = ({ pathContext }) => (
  <Layout>
    <Helmet title={`${pathContext.formationShop.titre} - ${pathContext.formationShop.reference} - Réservation`} />
    <div className="container mt-3">
      <h1>
        {pathContext.formationShop.titre}
        <br />
        <small>Réservation</small>
      </h1>

      {pathContext.formationShop.ffta ? (
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">Inscription préalable requise auprès de la FFTA</h4>
          Veuillez-vous inscrire sur l'intranet fédéral, puis payez votre inscription ici.
        </div>
      ) : (
        <></>
      )}

      <iframe
        id="haWidget"
        title="Paiement formation"
        allowTransparency
        scrolling="auto"
        src={`https://www.helloasso.com/associations/comite-regional-de-tir-a-l-arc-du-grand-est/${pathContext.shopAction.id}/widget`}
        style={iframeStyle}
      />

      <div style={propulsedStyle}>
        Propulsé par{" "}
        <a href="https://www.helloasso.com" rel="nofollow">
          HelloAsso
        </a>
      </div>
    </div>
  </Layout>
)

export default ReservationDetection
